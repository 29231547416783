<template>
    <div class="page" :class="{'cente': showSuccess}">
      <template v-if="!showSuccess">
        <h1 class="page-title">{{$i18n.t('learnRegister.pageTitle')}}</h1>
        <p class="page-desc">{{$i18n.t('learnRegister.pageDesc1')}}<br>{{$i18n.t('learnRegister.pageDesc2')}}</p>
        <div class="container">
          <div class="pricing-info-form">
            <el-form ref="form" label-position="left" label-width="140px" :model="form" :rules="rules">
              <el-row :gutter="60">
    
                <!-- <el-col>
                  <el-form-item :label="$i18n.t('contactUs.pricingInfo.Mandatory')" required/>
                </el-col> -->
    
                <el-col :md="12" :xs="24">
                  <el-form-item :label="$i18n.t('contactUs.pricingInfo.FirstName')" prop="firstName">
                    <el-input v-model="form.firstName" v-bind="inputAttr"/>
                  </el-form-item>
                </el-col>
                <el-col :md="12" :xs="24">
                  <el-form-item :label="$i18n.t('contactUs.pricingInfo.LastName')" prop="lastName">
                    <el-input v-model="form.lastName" v-bind="inputAttr"/>
                  </el-form-item>
                </el-col>
    
    
                <el-col :md="12" :xs="24">
                  <el-form-item :label="$i18n.t('contactUs.pricingInfo.Email')" prop="email">
                    <el-input v-model="form.email" v-bind="inputAttr"/>
                  </el-form-item>
                </el-col>
    
    
                <el-col :md="12" :xs="24">
                  <el-form-item :label="$i18n.t('contactUs.pricingInfo.Telephone')" prop="phone">
                    <!-- <el-input v-model="areaCode" v-bind="inputAttr" placeholder="Area Code" style="width: 30%;"/> - -->
                    <!-- <el-input v-model="form.phone" v-bind="inputAttr" placeholder="Telephone Number" style="width: 65%;"/> -->
                    <!-- <el-input v-model="form.phone" v-bind="inputAttr" placeholder="Area Code-Number eg. 0066-123456"/> -->
                    <el-input v-model="form.phone" v-bind="inputAttr" placeholder="Area Code-Number eg. 0066-123456"/>
                  </el-form-item>
                </el-col>
                <el-col :md="12" :xs="24">
                  <el-form-item :label="$i18n.t('contactUs.pricingInfo.CountryRegion')" prop="country">
                    <!-- <el-input v-model="form.country" v-bind="inputAttr"/> -->
                    <el-select placeholder="Country" v-model="form.country" clearable filterable style="width: 100%;">
                        <el-option v-for="(item, index) in countryArr" :key="index" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
    
    
                <el-col :md="12" :xs="24">
                  <el-form-item :label="$i18n.t('contactUs.pricingInfo.Company')" prop="company">
                    <el-input v-model="form.company" v-bind="inputAttr" :placeholder="$i18n.t('contactUs.pricingInfo.company_placeholder')"/>
                  </el-form-item>
                </el-col>
                <el-col :md="12" :xs="24">
                  <el-form-item :label="$i18n.t('contactUs.pricingInfo.Partner')" prop="partner">
                    <!-- <el-input v-model="form.partner" v-bind="inputAttr"/> -->
                    <el-select v-model="form.partner">
                      <el-option label="Tier 1 (National Distributor)" value="Tier 1 (National Distributor)"/>
                      <el-option label="Tier 1 (Project-based Distributor)" value="Tier 1 (Project-based Distributor)"/>
                      <el-option label="Tier 2 (Reseller)" value="Tier 2 (Reseller)"/>
                      <el-option label="Tier 2 (System Integrator)" value="Tier 2 (System Integrator)"/>
                      <el-option label="Tier 3 (Installer)" value="Tier 3 (Installer)"/>
                      <el-option label="HOLOWITS Staff" value="HOLOWITS Staff"/>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :md="12" :xs="24">
                  <el-form-item :label="$i18n.t('contactUs.pricingInfo.PreferLanguage')" prop="preferLanguage">
                    <!-- <el-input v-model="form.partner" v-bind="inputAttr"/> -->
                    <el-select v-model="form.preferLanguage" :placeholder="$i18n.t('contactUs.pricingInfo.preference')">
                      <el-option label="English" value="English"/>
                      <el-option label="Español (América Latina)" value="Español (América Latina)"/>
                      <el-option label="Português (Brasil)" value="Português (Brasil)"/>
                    </el-select>
                  </el-form-item>
                </el-col>
                
                <el-col v-if="!['HOLOWITS Staff', 'Other'].includes(form.partner)" style="margin-bottom: 22px;">
                  <p style="font-size: 12px; margin-bottom: 10px;">{{$i18n.t('learnRegister.hint2')}}</p>
                  <el-row :gutter="60">
                    <el-col :md="12" :xs="24">
                      <el-input v-bind:value="form.businessLicense" disabled/>
                      <!-- <div v-if="!check2" class="el-form-item__error" style="left: 30px;">{{$i18n.t('learnRegister.required')}}</div> -->
                    </el-col>
                    <el-col :md="12" :xs="24">
                      <el-upload class="upload-demo" :show-file-list="false" :headers="headers" action="/api/file/file/anyone/upload" :data="{...fileBaseData,bizType:'cooperation'}" :before-upload="beforeFileUpload1" :on-success="handleFileSuccess1">
                        <el-button type="primary">{{$i18n.t('learnRegister.upload')}}</el-button>
                      </el-upload>
                    </el-col>
                  </el-row>
                </el-col>

                <el-col style="margin-bottom: 22px;">
                  <p style="font-size: 12px; margin-bottom: 10px;"><span style="color:red; font-size: 16px;">* </span>{{$i18n.t('learnRegister.hint1')}}</p>
                  <el-row :gutter="60">
                    <el-col :md="12" :xs="24">
                      <el-input v-bind:value="form.staffCard" disabled/>
                      <div v-if="!check1" class="el-form-item__error" style="left: 30px;">{{$i18n.t('learnRegister.required')}}</div>
                    </el-col>
                    <el-col :md="12" :xs="24">
                      <el-upload class="upload-demo" :show-file-list="false" :headers="headers" action="/api/file/file/anyone/upload" :data="{...fileBaseData,bizType:'cooperation'}" :before-upload="beforeFileUpload" :on-success="handleFileSuccess">
                        <el-button type="primary">{{$i18n.t('learnRegister.upload')}}</el-button>
                      </el-upload>
                    </el-col>
                  </el-row>
                </el-col>

                <el-col>
                  <el-form-item prop="description" label-width="0">
                    <el-input :class="{'has-value':form.description}" v-model="form.description" type="textarea"
                              :autosize="{ minRows: 5, maxRows:8}" :maxlength="4000"
                              :placeholder="$i18n.t('learnRegister.inputPlaceholder')"/>
                  </el-form-item>
                </el-col>
    
                <el-col>
                  <el-form-item prop="subscribe" label-width="0" hide-required-asterisk>
                    <div class="agree-field">
                      <div class="agree-field-text">{{ $i18n.t('contactUs.pricingInfo.ConfirmText') }}</div>
                      <el-radio-group v-model="form.subscribe">
                        <el-radio :label="true">{{ $i18n.t('contactUs.pricingInfo.Yes') }}</el-radio>
                        <el-radio :label="false">{{ $i18n.t('contactUs.pricingInfo.No') }}</el-radio>
                      </el-radio-group>
                    </div>
                  </el-form-item>
                </el-col>
    
                <el-col>
                  <el-form-item prop="agree" label-width="0" hide-required-asterisk>
                    <div class="check-field">
                      <el-checkbox v-model="form.agree" :false-label="null"></el-checkbox>
                      <div class="check-field-wrap">
                        <span class="check-field-text">{{ $i18n.t('contactUs.pricingInfo.AgreeText') }}</span>
                        <span class="check-field-link">{{ $i18n.t('contactUs.pricingInfo.PrivacyPolicy') }}</span>
                        <span class="check-field-text">{{ $i18n.t('contactUs.pricingInfo.And') }}</span>
                        <span class="check-field-link">{{ $i18n.t('contactUs.pricingInfo.TermsOfUse') }}</span>
                      </div>
                    </div>
                  </el-form-item>
                </el-col>
    
              </el-row>
    
              <div>
                <div class="submit-btn" @click="submit" v-loading.fullscreen.lock="fullscreenLoading">
                  {{ $i18n.t('contactUs.pricingInfo.submit') }}
                </div>
              </div>
    
            </el-form>
          </div>
        </div>
      </template>
      <template v-if="showSuccess">
          <div class="success-panel">
              <img class="success-panel-icon" src="../../assets/image/login/icon_success.png" alt="success">
              <div class="success-panel-title">{{$i18n.t('learnRegister.successPanelTitle')}}</div>
              <!-- <p class="success-panel-text">{{$i18n.t('learnRegister.successPanelText')}}</p> -->
              <p class="success-panel-text">
                The application has been submitted and the administrator will contact you via email.<br>If your company want to become our Certified Tier 2 partner, please <strong @click="toPage" style="color: #d2333c; cursor: pointer;">click here</strong> to register. After one employee of your company passes HOLOWITS Certified Specialist Exam, your company can become our Certified Tier 2 partner and also can be show on our website.  
              </p>
          </div>
      </template>
    </div>
  </template>
  
  <script>
    import {initDicts} from '@/utils/commons';
    import {save} from '@/api/learnApi';
    import {cloneDeep} from 'lodash';
    import countrys from '@/utils/country.json';
    import db from "@/utils/localstorage";

    export default {
      name: 'PricingInfo',
      data() {
        let validatorEmail = (rule, value, callback) => {
          if (value.length > 254) {
            callback(new Error(this.$i18n.t('contactUs.pricingInfo.length-error')));
          } else if (value.indexOf('@') === -1) {
            callback(new Error(this.$i18n.t('contactUs.pricingInfo.emailValidator.at-error')));
          } else if (!/^(\w|[!#$%&’*+-/=?^`{}|~.])+@[^@]*$/.test(value)) {
            // invalidLetter = value.replace(/@[^@]+$/, '').replace(/\w|[!#$%&’*+-/=?^`{}|~.]/g, '');
            callback(new Error(this.$i18n.t('contactUs.pricingInfo.emailValidator.account-letter-forbidden')));
          } else if (/[.]{2}/.test(value)) {
            callback(new Error(this.$i18n.t('contactUs.pricingInfo.emailValidator.double-dot-error')));
          } else if (!/^.{1,63}@[^@]*$/.test(value)) {
            callback(new Error(this.$i18n.t('contactUs.pricingInfo.emailValidator.account-length-error')));
          } else if (!(/(^[^.].*@[^@]*$)/.test(value) && /^.*[^.]@[^@]*$/.test(value))) {
            callback(new Error(this.$i18n.t('contactUs.pricingInfo.emailValidator.prevDot-error')));
          } else if (!/^[^@]+@([0-9]|[A-Z]|[a-z]|[\-.])+$/.test(value)) {
            // invalidLetter = value.replace(/^[^@]+@/, '').replace(/[A-Za-z0–9\-.]/g, '');
            callback(new Error(this.$i18n.t('contactUs.pricingInfo.emailValidator.nextLetter-forbidden')));
          } else if (!(/^[^@]+@[^-].*$/.test(value) && /^[^@]+@.*[^-]$/.test(value))) {
            callback(new Error(this.$i18n.t('contactUs.pricingInfo.emailValidator.nextLine-error')));
          } else if (!/^[^@]+@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(value)) {
            callback(new Error(this.$i18n.t('contactUs.pricingInfo.emailValidator.domain-error')));
          } else {
            callback();
          }
  
        };
  
        let fieldRequired = (trigger = 'blur') => {
          return {
            required: true,
            message: this.$i18n.t('contactUs.pricingInfo.fieldRequired'),
            trigger,
          };
        };
  
        return {
          fullscreenLoading: false,
  
          form: {
            'firstName': '',
            'lastName': '',
            'company': '',
            'country': '',
            'description': '',
            'email': '',
            'partner': '',
            'preferLanguage': '',
            // 'scope': '',
            'phone': '',
            'staffCard': '',
            'businessLicense': '',
            'subscribe': null,
            'agree': null,
          },

          // areaCode: '',

          rules: {
            firstName: fieldRequired('blur'),
            lastName: fieldRequired('blur'),
            email: [
              fieldRequired('blur'),
              {required: true, validator: validatorEmail, trigger: 'blur'},
            ],
            phone: [
              fieldRequired('blur'),
              // {
              //   required: true, validator: (rele, value, callback) => {
              //     if (value) {
              //       if (/^((00)|\+)\d+$/.test(value)) {
              //         callback();
              //       } else {
              //         callback(new Error(this.$i18n.t('contactUs.pricingInfo.phoneFormatError')));
              //       }
              //     } else {
              //       callback(new Error(this.$i18n.t('contactUs.pricingInfo.fieldRequired')));
              //     }
              //   }, trigger: ['blur', 'change']
              // }
            ],
            country: fieldRequired('change'),
            company: fieldRequired('blur'),
            industry: fieldRequired('blur'),
            subscribe: fieldRequired('change'),
            agree: [
              fieldRequired('change'),
              {
                required: true, validator: (rule, value, callback) => {
                  if (!value) {
                    callback(new Error(this.$i18n.t('contactUs.pricingInfo.fieldRequired')));
                  } else {
                    callback();
                  }
                }, trigger: 'change',
              },
            ],
            partner: fieldRequired('blur'),
            preferLanguage: fieldRequired('blur')
            // scope: fieldRequired('blur')
          },
  
          inputAttr: {
            placeholder: this.$i18n.t('contactUs.pricingInfo.placeholder'),
            maxlength: '100',
            clearable: true,
          },
  
          prevPagePath: '/',

          countryArr: [],
          showSuccess: false,
          headers: {},

          fileBaseData: {
            bucket: "hwtresources",
            storageType: "HUAWEI_OSS",
          },

          check1: true,
          check2: true,
        };
      },
      created() {
        this.countryArr = countrys.country.map(item => {
            return {label: item, value: item}
        })
        this.headers = {
          // token: 'Bearer ' + db.get('HOLOWITS_TOKEN'),
          tenant: 'aG9sb3dpdHM=',
          Authorization: `Basic ${Base64.encode(`${process.env.VUE_APP_CLIENT_ID}:${process.env.VUE_APP_CLIENT_SECRET}`)}`
        }
      },
      mounted() {
        this.alertTips()
      },
      beforeRouteEnter(to, from, next) {
        next(vm => {
          vm.prevPagePath = from.path;
        });
      },
      methods: {
        alertTips() {
          const h = this.$createElement;
          this.$confirm('',{
            title: 'Tips',
            message: h('div', null, [
              h('p', null, 'Please note: Before applying for an exam account, please log in to your personal account in the top right corner.'),
              h('a', {attrs: { href: 'https://www.holowits.com/login' }, style: {color: '#d2333c'} }, 'https://www.holowits.com/login'),
              h('p', null, 'Otherwise, your exam account application will not be submitted.'),
            ]),
            closeOnClickModal: false
          }).then(_ => {}).catch(_ => {});
        },
        toPage() {
          this.$router.push({path: '/reseller'})
        },
        beforeFileUpload(file) {
          const FileExt = file.name.replace(/.+\./, '').toLowerCase()
          const isLt2M = file.size / 1024 / 1024 < 10;
          const imageExt = ['jpg', 'png', 'webp', 'jpeg'];
          if (!imageExt.includes(FileExt)) {
            this.$message.error('Format error, please upload a jpg or png file')
            return false;
          }
          if (!isLt2M) {
            this.$message.error('The file size should not exceed 10M')
            return false;
          }
          return true;
        },
        beforeFileUpload1(file) {
          const FileExt = file.name.replace(/.+\./, '').toLowerCase()
          const isLt2M = file.size / 1024 / 1024 < 50;
          const imageExt = ['jpg', 'png', 'webp', 'jpeg'];
          if (!imageExt.includes(FileExt)) {
            this.$message.error('Format error, please upload a jpg or png file')
            return false;
          }
          if (!isLt2M) {
            this.$message.error('The file size should not exceed 50M')
            return false;
          }
          return true;
        },
        handleFileSuccess(res, file) {
          if (!res.isSuccess) {
            this.$message.error('System Error,Upload Fail');
            return;
          }
          this.check1 = true
          this.form.staffCard = res.data.url
        },
        handleFileSuccess1(res, file) {
          if (!res.isSuccess) {
            this.$message.error('System Error,Upload Fail');
            return;
          }
          this.check2 = true
          this.form.businessLicense = res.data.url
        },
        submit() {
          this.$refs.form.validate((valid) => {
            if (!this.form.businessLicense && !['HOLOWITS Staff', 'Other'].includes(this.form.partner)) {
                this.check2 = false
            } else {
                this.check2 = true
            }
            if (!this.form.staffCard) {
                this.check1 = false
            } else {
                this.check1 = true
            }
            if (valid && this.check1) {
              this.form.phone = `${this.areaCode} - ${this.form.phone}`
              let bodyData = cloneDeep(this.form);
              save(bodyData).then(({data: {data, isSuccess}}) => {
                if (isSuccess) {
                  this.$message.success('Submit Success');
                  this.showSuccess = true
                } else {
                  this.alertTips()
                }
              })
            }
            // this.$router.push({path: this.prevPagePath});
          }); 
        },
      },
    };
  </script>
  
  <style scoped lang="less">
    /deep/.el-upload {
      .el-upload__input {
        display: none !important;
      }
    }
    .page {
      position: relative;
      padding-bottom: 20px;
      background-color: #F8F8F8;
      min-height: inherit;
      &:after {
        content: '';
        background-color: #F8F8F8;
        position: absolute;
        height: 100vh;
        left: 0;
        top: 0;
        right: 0;
        z-index: -1;
      }
    }
    .cente {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    .success-panel {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      .success-panel-icon {
        width: 88px;
        margin-bottom: 10px;
      }

      .success-panel-title {
        font-size: 20px;
        font-weight: bold;
        color: #333333;
      }

      .success-panel-text{
        font-size: 18px;
        font-weight: 400;
        color: #666666;
        max-width: 820px;
        margin: 30px 0 50px;
      }

      .el-button{
        display: block;
        width: 307px;
        max-width: 100%;
        height: 66px;
        font-size: 18px;
        border-radius: 8px;
      }
    }

    .page-title {
      text-align: center;
      padding: 50px 0 26px;
      font-size: 42px;
      font-weight: 400;
      color: #000000;
    }
  
    .page-desc {
      font-size: 18px;
      font-weight: 400;
      color: #666666;
      text-align: center;
      margin-bottom: 26px;
    }
  
  
    .pricing-info-form {
      background-color: #FFFFFF;
      padding: 40px 60px;
      transition: .3s;
  
      @media (max-width: 750px) {
        & {
          padding: 30px;
        }
  
        .submit-btn {
          height: 50px;
          max-width: 100%;
        }
      }
  
  
  
      /deep/ .el-form-item {
        //display: flex;
        //width: 100%;
        //margin-bottom: 30px;
  
        //.el-form-item__label {
        //  white-space: nowrap;
        //}
  
        .el-input, .el-select {
          width: 100%;
        }
  
        //.el-form-item__content {
        //  flex: 1;
        //  width: 100%;
        //}
  
        .el-textarea {
          &.has-value:after {
            display: none;
          }
  
          &:after {
            content: '*';
            color: #f56c6c;
            position: absolute;
            top: 0;
            left: 7px;
            line-height: 32px;
          }
        }
      }
  
      .agree-field {
        .agree-field-text {
          font-weight: 400;
          color: #666666;
          line-height: 1.6;
  
          &:before {
            content: '*';
            color: #f56c6c;
            margin-right: 4px;
          }
        }
      }
  
      .check-field {
        line-height: 1.6;
        display: flex;
  
        .check-field-wrap {
          flex: 1;
          width: 100%;
        }
  
        .el-checkbox {
          margin-right: 10px;
        }
  
        .check-field-text {
          color: #999999;
          font-weight: 400;
        }
  
        .check-field-link {
          color: #333333;
          font-weight: 400;
          padding: 0 5px;
          cursor: pointer;
  
          &:hover {
            text-decoration: underline;
          }
  
          &:active {
            opacity: .7;
          }
        }
      }
    }
  
    .submit-btn {
      max-width: 250px;
      height: 60px;
      background: #C7000B;
      border-radius: 54px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      font-weight: 400;
      color: #FFFFFF;
      margin: 0 auto;
      cursor: pointer;
      user-select: none;
      transition: .1s ease-out;
      margin-top: 20px;
  
      &:hover {
        opacity: .8;
      }
  
      &:active {
        opacity: 1;
      }
    }
  </style>
  